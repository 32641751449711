import { SpecialConditionType, LeaseVariationKindWithField } from '@register'

export const getTypeByVariationType = (
  type: LeaseVariationKindWithField,
): SpecialConditionType => {
  switch (type) {
    case LeaseVariationKindWithField.ADD_CLAUSE:
    case LeaseVariationKindWithField.CHANGE_CLAUSE:
    case LeaseVariationKindWithField.REMOVE_CLAUSE: {
      return SpecialConditionType.CLAUSE
    }
    case LeaseVariationKindWithField.ADD_DEFINITION:
    case LeaseVariationKindWithField.CHANGE_DEFINITION:
    case LeaseVariationKindWithField.REMOVE_DEFINITION: {
      return SpecialConditionType.DEFINITION
    }

    // case LeaseVariationKindWithField.ADD_VARIATION:
    // case LeaseVariationKindWithField.CHANGE_VARIATION: {
    //   return SpecialConditionType.VARIATION
    // }
    default: {
      return type as never
    }
  }
}
