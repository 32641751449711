import { SpecialConditionKind, LeaseVariationKindWithField } from '@register'

// TODO: fix return type
export const getKindByType = (
  type: LeaseVariationKindWithField,
): SpecialConditionKind => {
  switch (type) {
    case LeaseVariationKindWithField.ADD_CLAUSE:
    case LeaseVariationKindWithField.ADD_DEFINITION:
    case LeaseVariationKindWithField.ADD_VARIATION: {
      return SpecialConditionKind.ADDED
    }

    case LeaseVariationKindWithField.CHANGE_CLAUSE:
    case LeaseVariationKindWithField.CHANGE_DEFINITION:
    case LeaseVariationKindWithField.CHANGE_VARIATION: {
      return SpecialConditionKind.CHANGED
    }

    case LeaseVariationKindWithField.REMOVE_CLAUSE:
    case LeaseVariationKindWithField.REMOVE_DEFINITION: {
      return SpecialConditionKind.DELETED
    }
    default: {
      return type satisfies never
    }
  }
}
