import type { LeaseVariationKindWithField, LeaseFieldType } from '@register'

export const parseFieldTypeFromKindWithField = (
  kindWithField: LeaseVariationKindWithField,
): LeaseFieldType => {
  let fieldType = kindWithField.split('_')[1].toLowerCase()
  if (fieldType === 'variation') {
    fieldType = 'field'
  }
  return fieldType as LeaseFieldType
}
