import type { LeaseFieldType, ILeaseField } from '@register'
import { LeaseVariationType, LeaseVariationKindWithField } from '@register'
import { variationFieldConfigFactory } from './variationFieldConfigFactory'
import type { VariationFieldConfig } from './types'
export * from './types'

export const useFieldConfig = (
  config: Ref<{
    fieldType?: LeaseFieldType
    type: LeaseVariationType | undefined
    kind: LeaseVariationKindWithField | undefined
    leaseFieldId: string | undefined
    allFields: ILeaseField[]
  }>,
) => {
  // FIELDS
  const { lease, fields } = useCurrentLeaseDocument()

  // FIELD CONFIG
  return computed<VariationFieldConfig | undefined>(() => {
    const {
      fieldType: _fieldType,
      type,
      kind: _kind,
      leaseFieldId,
      allFields,
    } = config.value

    // If we don't have `type`, we are creating a new variation but the form still empty.
    if (!type) return

    // If we don't have `kind`, we are creating a new variation but the kind is not selected in the form yet.
    const kind = resolveKind(type, _kind)
    if (!kind) return

    // At this point, fieldType should always be defined. But we still check it just to make it sure.
    // If we are editing a variation, we get the `fieldType` from it. Otherwise, we parse from the kind.
    const fieldType = _fieldType ?? parseFieldTypeFromKindWithField(kind)
    if (!fieldType) return

    return variationFieldConfigFactory(fieldType, {
      type,
      kind,
      leaseFieldId,
      lease: lease.value,
      fields: fields.value,
      allFields,
      clausesAndDefinitions: lease.value?.clausesAndDefinitions.table ?? [],
    })
  })

  function resolveKind(
    type: LeaseVariationType,
    kind: LeaseVariationKindWithField | undefined,
  ) {
    return type === LeaseVariationType.VARIATION
      ? kind
      : LeaseVariationKindWithField.ADD_VARIATION
  }
}
