import type { FormKitNode } from '@formkit/core'
import { getTypeByVariationType } from '@register/components/Review/SpecialConditions/composables/getTypeByVariationType'
import { LeaseVariationType, useFieldConfig } from '@register'
import { getKindByType } from './getKindByType'
import type { ISpecialConditionForm } from '../types'

export const useSpecialConditionForm = () => {
  const instance = getCurrentInstance()

  // GLOBAL
  const divisionId = getCurrentDivisionId()
  const leaseId = getCurrentLeaseId()

  // FORM
  const formValue = ref<ISpecialConditionForm>({} as ISpecialConditionForm)

  // FIELD CONFIG
  const { data: availableFields, isFetching: isFetchingFields } =
    useSpecialConditionAvailableFields(
      divisionId,
      leaseId,
      computed(() =>
        formValue.value.kind ? getKindByType(formValue.value.kind) : undefined,
      ),
    )
  const fieldConfig = useFieldConfig(
    computed(() => ({
      type: LeaseVariationType.VARIATION,
      kind: formValue.value.kind,
      leaseFieldId: formValue.value.leaseFieldId,
      allFields: availableFields.value ?? [],
    })),
  )

  // SUBMIT
  const { mutateAsync: saveSpecialCondition } =
    useApiLeaseSpecialConditionCreate(divisionId, leaseId)
  const submitHandler = useSubmitHandler<ISpecialConditionForm>(
    (data) =>
      saveSpecialCondition({
        type: getTypeByVariationType(data.kind),
        kind: getKindByType(data.kind),
        leaseFieldId: data.leaseFieldId,
      }),
    {
      onSuccess: () => {
        instance?.emit('submit')
      },
      loadingMessage: computed(
        () => `Adding ${fieldConfig.value?.leaseField.label}...`,
      ),
      successMessage: computed(
        () => `${fieldConfig.value?.leaseField.label} added successfully`,
      ),
      errorMessage: computed(
        () =>
          `Error occurs while adding the ${fieldConfig.value?.leaseField.label}`,
      ),
    },
  )
  const onSubmit = (data: ISpecialConditionForm, node: FormKitNode) => {
    // We don't await the submit handler to prevent form disabled state while saving.
    submitHandler(data, node)
  }

  return {
    formValue,
    fieldConfig,
    isFetchingFields,
    onSubmit,
  }
}
