/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum SpecialConditionKind {
    CHANGED = 'changed',
    ADDED = 'added',
    DELETED = 'deleted',
    ITEM_CHANGED = 'itemChanged',
}
