import { useMutation, useQueryClient } from '@tanstack/vue-query'
import type {
  ICreateLeaseSpecialConditionRequest,
  ILeaseVariation,
} from '@register'

export const useApiLeaseSpecialConditionCreate = (
  companyId: string,
  leaseId: string,
) => {
  const queryClient = useQueryClient()

  let controller: AbortController | null = null
  let signal: AbortSignal | null = null

  return useMutation({
    mutationFn: (body: ICreateLeaseSpecialConditionRequest) => {
      // If a previous request is in progress, abort it
      if (controller) {
        controller.abort()
      }

      // Create a new AbortController and signal for the upcoming request
      controller = new AbortController()
      signal = controller.signal

      return fetchAPI<ILeaseVariation>(
        `/companies/${companyId}/leaseRegister/leases/${leaseId}/specialConditions`,
        {
          method: 'POST',
          body,
          signal,
        },
      )
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['getLeaseDocumentId', companyId, leaseId],
      })
    },
  })
}
