import { useQuery } from '@tanstack/vue-query'
import type { SpecialConditionKind, ILeaseField } from '@register'

export const useSpecialConditionAvailableFields = (
  companyId: string,
  leaseId: string,
  kind: Ref<SpecialConditionKind | undefined>,
) => {
  return useQuery({
    queryKey: [
      'companies',
      companyId,
      'leases',
      leaseId,
      'specialConditionKinds',
      kind,
      'availableFields',
    ],
    queryFn: () =>
      fetchAPI<ILeaseField[]>(
        `/companies/${companyId}/leaseRegister/leases/${leaseId}/specialConditionKinds/${unref(
          kind,
        )}/availableFields`,
      ),
    enabled: computed(() => !!unref(kind)),
    gcTime: 200,
    staleTime: 0,
  })
}
