<script setup lang="ts">
import type { FormKitNode } from '@formkit/core'
import { VueFinalModal } from 'vue-final-modal'
import { useSpecialConditionForm } from './composables/useSpecialConditionForm'
import { leaseSpecialConditionKindWithFieldOptions } from '@register'

const emit = defineEmits(['close', 'submit'])

const form = ref<{ node: FormKitNode }>()
const { formValue, fieldConfig, isFetchingFields, onSubmit } =
  useSpecialConditionForm()
</script>

<template>
  <VueFinalModal
    class="top-0 flex items-start justify-center bg-black/60 transition-all"
    content-class="w-full"
    overlay-transition="vfm-fade"
    :content-transition="
      {
        'enter-active-class': 'transition duration-300 ease-in-out transform',
        'enter-from-class': '-translate-y-6 opacity-0',
        'enter-to-class': 'translate-y-0 opacity-100',
        'leave-active-class': 'transition duration-300 ease-in-out transform',
        'leave-to-class': 'translate-y-6 opacity-0',
        'leave-from-class': '-translate-y-0 opacity-100',
      } as any
    "
  >
    <ModalContainer
      title="Add a special condition"
      close-on-click
      @close="emit('close')"
    >
      <FormKit
        ref="form"
        v-slot="{ loading }"
        v-model="formValue"
        type="form"
        :actions="false"
        class="flex"
        @submit="onSubmit"
      >
        <FormKit
          type="listbox"
          name="kind"
          label="Action"
          :searchable="true"
          :options="leaseSpecialConditionKindWithFieldOptions"
          dirty-behavior="compare"
          validation="required"
          outer-class="w-full mt-6"
        />

        <FormKit
          v-if="fieldConfig"
          :key="`leaseField_${fieldConfig.key}`"
          type="listbox"
          name="leaseFieldId"
          :searchable="true"
          :label="fieldConfig.leaseField.label"
          :options="fieldConfig.leaseField.options"
          :loading="isFetchingFields"
          dirty-behavior="compare"
          validation="required"
          outer-class="w-full mt-6"
        />

        <div class="mt-6 flex justify-end">
          <Button
            type="submit"
            :loading="form?.node.context.state.loading"
            class="mr-2"
            @click="undefined"
          >
            <Spinner v-if="loading || form?.node.context.state.loading" />
            Add
          </Button>
        </div>
      </FormKit>
    </ModalContainer>
  </VueFinalModal>
</template>
